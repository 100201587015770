<mat-drawer-container class="site-container" hasBackdrop="true">
  <mat-drawer #drawer class="sidenav" mode="over">
    <div class="row">
      <div class="col s12 center-align">
        <img src="assets/img/profile-picture.jpeg" alt="" class="responsive-img circle">
        <span class="col s12 name">{{websiteContent?.personalInformation?.name}}</span>
        <span class="col s12">
          <a href="mailto:{{websiteContent?.personalInformation?.email}}">
            {{websiteContent?.personalInformation?.email}}
          </a>
        </span>
      </div>
      <div class="col s12">
        <hr>
      </div>
      <div class="col s12 sidenav-menu">
        <a class="sidenav-button" routerLink="/about" [routerLinkActive]="['is-active']" (click)="drawer.close()">About me </a>
        <a class="sidenav-button" routerLink="/career" [routerLinkActive]="['is-active']" (click)="drawer.close()">Career</a>                     
        <a class="sidenav-button" routerLink="/resume" [routerLinkActive]="['is-active']" (click)="drawer.close()">Resume</a>  
      </div>      
    </div>
  </mat-drawer>
  <div class="row">
    <div class="col s12 m2 l2">
      <a class="black-text">
        <i class="medium material-icons" (click)="drawer.toggle()">
          menu
        </i>
      </a>
    </div>
    <div class="col s12 m9 l9 content-container">
      <router-outlet></router-outlet>
    </div>
    <div class="col s12 m1 l1 hide-on-small-and-down"></div>
  </div>
</mat-drawer-container>