<div class="hide-on-med-and-up">
  <div class="col s12 m3 l3 center-align">
    <img src="assets/img/profile-picture.jpeg" alt="" class="responsive-img circle">
  </div>
  <div class="col s12 center-align">
    <div class="personal-information">
      <h1>{{websiteContent?.personalInformation?.name}}</h1>
      <ul>
        <li>Age: {{myAge}} ({{dateOfBirth}})</li>
        <li>Nationality: {{websiteContent?.personalInformation?.nationality}}</li>
        <li>
          LinkedIn: 
          <a [href]="websiteContent?.personalInformation?.linkedIn" target="_blank">
            {{websiteContent?.personalInformation?.linkedIn}}
          </a>
        </li>
        <li>
          GitHub: 
          <a [href]="websiteContent?.personalInformation?.github" target="_blank">
            {{websiteContent?.personalInformation?.github}}
          </a>
        </li>
        <li>
          Email:
          <a href="mailto:{{websiteContent?.personalInformation?.email}}">
            {{websiteContent?.personalInformation?.email}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="hide-on-small-only">
  <div class="col s12 header">
    <img src="assets/img/profile-picture.jpeg" alt="" class="responsive-img circle">
    <div class="personal-information">
      <h1>{{websiteContent?.personalInformation?.name}}</h1>
      <ul>
        <li>Age: {{myAge}} ({{dateOfBirth}})</li>
        <li>Nationality: {{websiteContent?.personalInformation?.nationality}}</li>
        <li>
          LinkedIn: 
          <a [href]="websiteContent?.personalInformation?.linkedIn" target="_blank">
            {{websiteContent?.personalInformation?.linkedIn}}
          </a>
        </li>
        <li>
          GitHub: 
          <a [href]="websiteContent?.personalInformation?.github" target="_blank">
            {{websiteContent?.personalInformation?.github}}
          </a>
        </li>
        <li>
          Email:
          <a href="mailto:{{websiteContent?.personalInformation?.email}}">
            {{websiteContent?.personalInformation?.email}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="col s12 section">
  <h1>
    Hey, I'm {{firstName}}, welcome to my website!
  </h1>
  {{websiteContent?.personalInformation?.biography}}
</div>

<div class="col s12 section">
  <h1>Personal interests</h1>
  Here's a list of the kind of stuff I usually go to for fun
  <ul>
    <li *ngFor="let interest of websiteContent?.personalInterests">{{interest}}</li>
  </ul>
</div>

<div class="col s12 section">
  <h1>Education</h1>
  <div class="col s12 m12 l6 portfolio-item" *ngFor="let education of websiteContent?.education">        
    <div class="col s12 m3 l3" style="padding:0;">
      <img [src]="education.imgSrc">
    </div>
    <div class="col s12 m9 l9 description">
      <h1>{{education.name}}</h1>        
      <ul>
        <li>{{getFormattedEducationPeriod(education)}}</li>
        <li>{{education.description}}</li>
      </ul>
    </div>    
  </div>
</div>

<div class="col s12 section">
  <h1>Some technical stuff I happen to know...</h1>
  <div class="col s12 m12 l6 portfolio-item" *ngFor="let technicalKnowledge of websiteContent?.technicalKnowledge">    
    <div class="col s12 m3 l3" style="padding:0;">
      <img [src]="technicalKnowledge.emblemSrc">
    </div>
    <div class="col s12 m9 l9 description">
      <h1>{{technicalKnowledge.title}}</h1>
      <ul>
        <li *ngFor="let description of technicalKnowledge.description" [innerHTML]="description"></li>
      </ul>
    </div>
  </div>
  
</div>